$purple: #8D53FF;
$pink: #CA6BE6;
$white: #f8f8f8;
$dark: #2f2f2f;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #c9c9c9;

$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);
}
:export {
  dark:$dark;
  light:$white
}