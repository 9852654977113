:root {
  --chatbot-height: 70vh;
  --chatbot-width: 90vw;
  --bubble-width: 60vw;
  --bubble-font-size: 1.3rem;
  --input-text-color: #C8C8C8

}

*:focus {
  outline: none;
}

/* this is literally the HEIGHT of the container that contains the text IN a user sent bubble */
.react-chatbot-kit-user-chat-message-container {
    display: flex;
    padding: 0;
    margin: 0px;
    justify-content: flex-end;
  }
  
  .react-chatbot-kit-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12.5px;
    background-color: #3d4e8d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-chatbot-kit-user-avatar-icon {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
  
  .react-chatbot-kit-user-chat-message {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-size: var(--bubble-font-size);
    max-width: calc(var(--bubble-width));
    color: #585858;
    word-wrap: break-word;
    font-weight: medium;
    position: relative;
    text-align: left;
  }
  
  .react-chatbot-kit-user-chat-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #f1f1f1;
    position: absolute;
    right: -7px;
    top: 13px;
  }
  
  /* this is literally the container of the message itself, the container the text sets in, IN a single bubble */
  .react-chatbot-kit-chat-bot-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
  }
  
  .react-chatbot-kit-chat-bot-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-chatbot-kit-chat-bot-avatar-icon {
    fill: #494646;
    width: 22.5px;
    height: 22.5px;
  }
  
  .react-chatbot-kit-chat-bot-avatar-letter {
    color: #1d1d1d;
    margin: 0;
    padding: 0;
  }
  
  .react-chatbot-kit-chat-bot-message {
    background-color: #2898ec;
    padding: 10px;
    border-radius: 5px;
    font-size: var(--bubble-font-size);
    color: #fff;
    font-weight: medium;
    position: relative;
    max-width: calc(var(--bubble-width));
    margin-left: .2rem;
    text-align: left;
  }
  
  .react-chatbot-kit-chat-bot-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #2898ec;
    position: absolute;
    left: -7px;
    top: 13px;
  }
  
  .react-chatbot-kit-chat-bot-loading-icon-container {
    height: 17px;
    width: 25px;
  }
  
  .chatbot-loader-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  #chatbot-loader #chatbot-loader-dot1 {
    animation: load 1s infinite;
  }
  
  #chatbot-loader #chatbot-loader-dot2 {
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }
  
  #chatbot-loader #chatbot-loader-dot3 {
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }
  
  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
/* Set the opacity for the chat pane container */
.react-chatbot-kit-chat-container {
    position: relative;
    width: var(--chatbot-width);
    height: var(--chatbot-height);
    opacity: 0.8; /* Adjust this value to control the translucency of the chat pane */
    padding: 0;
    margin: 0;
}

/* Ensure the chat bubbles have full opacity */
.react-chatbot-kit-chat-inner-container {
    background-color: #202020;
    border-radius: 5px;
    height: 100%;
}
  
  .react-chatbot-kit-chat-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #efefef;
    font-family: Arial;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #514f4f;
    padding: 12.5px;
    font-weight: bold;
  }
  
  .react-chatbot-kit-chat-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 1;
    display: flex;
  }
  
  /* this is the container that the chat bubbles live in, this is like the ambient space around the bubbles */
  .react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: scroll;
    height: calc(var(--chatbot-height) - 10vh);
  }

  div::-webkit-scrollbar-thumb {
    background-color: blue;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #ADD8E6;  /* creates padding around scroll thumb */
  }
  
  .react-chatbot-kit-chat-input {
    width: 100%;
    padding: 12.5px;
    border: none;
    font-size: 18px;
    border-top: 1px solid #272832;
    background-color: #272832;
    border-bottom-left-radius: 5px;
  }
  
  .react-chatbot-kit-chat-input-form {
    width: 100%;
    display: flex;
  }
  
  .react-chatbot-kit-chat-input::placeholder {
    color: var(--input-text-color);
  }

  input, select, textarea{
    color: var(--input-text-color);
}
  
  .react-chatbot-kit-chat-btn-send {
    background-color: #2898ec;
    width: 100px;
    border: none;
    color: #fff;
    border-bottom-right-radius: 5px;
  }
  
  .react-chatbot-kit-chat-btn-send-icon {
    fill: #fff;
    width: 15px;
    margin: 0 auto;
  }
  
  .react-chatbot-kit-error {
    background-color: #FF0000;
    border-radius: 3px;
    padding: 15px;
  }
  
  .react-chatbot-kit-error-container {
    width: 260px;
  }
  
  .react-chatbot-kit-error-header {
    font-size: 1.3rem;
    color: #1d1d1d;
    margin-bottom: 30px;
  }
  
  .react-chatbot-kit-error-docs {
    display: block;
    margin: 25px auto;
    color: rgb(56, 104, 139);
    padding: 8px;
    border: 1px solid rgb(40, 152, 236);
    width: 130px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
  }
  
  /* Resetting Padding and Margin */
.react-chatbot-kit-chat-container,
.react-chatbot-kit-chat-inner-container,
.react-chatbot-kit-chat-message-container {
    padding: 0;
    margin: 0;
}

  